var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Stranka","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":"Naziv"}},[_c('validation-provider',{attrs:{"name":"Naziv","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":"Naslov","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.address),callback:function ($$v) {_vm.$set(_vm.object, "address", $$v)},expression:"object.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Alias"}},[_c('validation-provider',{attrs:{"name":"Alias","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.alias),callback:function ($$v) {_vm.$set(_vm.object, "alias", $$v)},expression:"object.alias"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Davčna številka"}},[_c('validation-provider',{attrs:{"name":"tax_number","rules":"required|numeric|min:8|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.tax_number),callback:function ($$v) {_vm.$set(_vm.object, "tax_number", $$v)},expression:"object.tax_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Zaključek dneva"}},[_c('validation-provider',{attrs:{"name":"closing_time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{model:{value:(_vm.object.closing_time),callback:function ($$v) {_vm.$set(_vm.object, "closing_time", $$v)},expression:"object.closing_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Država"}},[_c('validation-provider',{attrs:{"name":"Država","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryObjects(),"reduce":function (item) { return item.id; },"clearable":false},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
var img = ref.img;
return [_c('div',[_c('b-img',{attrs:{"height":"14px","width":"22px","src":img}}),_vm._v(" "+_vm._s(label)+" ")],1)]}},{key:"option",fn:function(option){return [_c('b-img',{attrs:{"height":"14px","width":"22px","src":option.img}}),_vm._v(" "+_vm._s(option.label)+" ")]}}],null,true),model:{value:(_vm.object.country),callback:function ($$v) {_vm.$set(_vm.object, "country", $$v)},expression:"object.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Verzija"}},[_c('validation-provider',{attrs:{"name":"tier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (ele) { return ele.id; },"clearable":false,"options":_vm.tiers,"appendToBody":""},model:{value:(_vm.object.tier),callback:function ($$v) {_vm.$set(_vm.object, "tier", $$v)},expression:"object.tier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Prodajalec"}},[_c('validation-provider',{attrs:{"name":"prodajalec"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"clearable":true,"options":_vm.resellers,"appendToBody":""},model:{value:(_vm.object.reseller_id),callback:function ($$v) {_vm.$set(_vm.object, "reseller_id", $$v)},expression:"object.reseller_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Davčni zavezanec"}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.is_tax_payer),callback:function ($$v) {_vm.$set(_vm.object, "is_tax_payer", $$v)},expression:"object.is_tax_payer"}})],1),_c('b-form-group',{attrs:{"label":"Onemogočen"}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.disabled),callback:function ($$v) {_vm.$set(_vm.object, "disabled", $$v)},expression:"object.disabled"}})],1),(_vm.currentTier)?_c('b-form-group',{attrs:{"label":"Delo s povratnimi embalažami"}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.returnable_packaging_enabled),callback:function ($$v) {_vm.$set(_vm.object, "returnable_packaging_enabled", $$v)},expression:"object.returnable_packaging_enabled"}})],1):_vm._e()],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Uredi")])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Dodaj")])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }